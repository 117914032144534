import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const CryptoHomeList = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  return (
    <div className="pt-12 pb-12 mt-4">
      <div className="wrap-filter-swiper">
        <h5>
          <a href="" className="cryptex-rating">
            <i className="icon-star" />
            Traing Market List
          </a>
        </h5>
        <div className="swiper-wrapper1 menu-tab-v3 mt-12">
          <div
            onClick={() => handleTabClick(0)}
            className={
              activeTab === 0
                ? "swiper-slide1 nav-link active"
                : "swiper-slide1 nav-link"
            }
          >
            Favorites
          </div>
          <div
            onClick={() => handleTabClick(1)}
            className={
              activeTab === 1
                ? "swiper-slide1 nav-link active"
                : "swiper-slide1 nav-link"
            }
          >
            Top Gainer
          </div>
          <div
            onClick={() => handleTabClick(2)}
            className={
              activeTab === 2
                ? "swiper-slide1 nav-link active"
                : "swiper-slide1 nav-link"
            }
          >
            Popular
          </div>
          <div
            onClick={() => handleTabClick(3)}
            className={
              activeTab === 3
                ? "swiper-slide1 nav-link active"
                : "swiper-slide1 nav-link"
            }
          >
            New token
          </div>
        </div>
      </div>

      <div className="tab-content mt-8">
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          initialSlide={activeTab}
          onSlideChange={(swiper) => setActiveTab(swiper.activeIndex)}
        >
          <SwiperSlide key={0}>
            <div
              className="tab-pane fade show active"
              id="favorites"
              role="tabpanel"
            >
              <div className="d-flex justify-content-between">
                Name
                <p className="d-flex gap-8">
                  <span>Last price</span>
                  <span>Change</span>
                </p>
              </div>
              <ul className="mt-16">
                <li>
                  <a
                    href="choose-payment.html"
                    className="coin-item style-2 gap-12"
                  >
                    <img
                      src="images/coin/coin-6.jpg"
                      alt="img"
                      className="img"
                    />
                    <div className="content">
                      <div className="title">
                        <p className="mb-4 text-button">ETH</p>
                        <span className="text-secondary">$360,6M</span>
                      </div>
                      <div className="d-flex align-items-center gap-12">
                        <span className="text-small">$1.878,80</span>
                        <span className="coin-btn decrease">-1,62%</span>
                      </div>
                    </div>
                  </a>
                </li>
                <li className="mt-16">
                  <a
                    href="choose-payment.html"
                    className="coin-item style-2 gap-12"
                  >
                    <img
                      src="images/coin/coin-7.jpg"
                      alt="img"
                      className="img"
                    />
                    <div className="content">
                      <div className="title">
                        <p className="mb-4 text-button">arb_ETH</p>
                        <span className="text-secondary">$132,18M</span>
                      </div>
                      <div className="d-flex align-items-center gap-12">
                        <span className="text-small">$1.878,80</span>
                        <span className="coin-btn increase">+1,62%</span>
                      </div>
                    </div>
                  </a>
                </li>
                <li className="mt-16">
                  <a
                    href="choose-payment.html"
                    className="coin-item style-2 gap-12"
                  >
                    <img
                      src="images/coin/coin-8.jpg"
                      alt="img"
                      className="img"
                    />
                    <div className="content">
                      <div className="title">
                        <p className="mb-4 text-button">WBTC</p>
                        <span className="text-secondary">$50,56M</span>
                      </div>
                      <div className="d-flex align-items-center gap-12">
                        <span className="text-small">$30.001,96</span>
                        <span className="coin-btn decrease">-1,64%</span>
                      </div>
                    </div>
                  </a>
                </li>
                <li className="mt-16">
                  <a
                    href="choose-payment.html"
                    className="coin-item style-2 gap-12"
                  >
                    <img
                      src="images/coin/coin-3.jpg"
                      alt="img"
                      className="img"
                    />
                    <div className="content">
                      <div className="title">
                        <p className="mb-4 text-button">ARB</p>
                        <span className="text-secondary">$31,55M</span>
                      </div>
                      <div className="d-flex align-items-center gap-12">
                        <span className="text-small">$1,11</span>
                        <span className="coin-btn increase">+3,71%</span>
                      </div>
                    </div>
                  </a>
                </li>
                <li className="mt-16">
                  <a
                    href="choose-payment.html"
                    className="coin-item style-2 gap-12"
                  >
                    <img
                      src="images/coin/coin-9.jpg"
                      alt="img"
                      className="img"
                    />
                    <div className="content">
                      <div className="title">
                        <p className="mb-4 text-button">WETH</p>
                        <span className="text-secondary">$24,34M</span>
                      </div>
                      <div className="d-flex align-items-center gap-12">
                        <span className="text-small">$1.878,56</span>
                        <span className="coin-btn decrease">-1,62%</span>
                      </div>
                    </div>
                  </a>
                </li>
                <li className="mt-16">
                  <a
                    href="choose-payment.html"
                    className="coin-item style-2 gap-12"
                  >
                    <img
                      src="images/coin/coin-10.jpg"
                      alt="img"
                      className="img"
                    />
                    <div className="content">
                      <div className="title">
                        <p className="mb-4 text-button">MATIC</p>
                        <span className="text-secondary">$19,36M</span>
                      </div>
                      <div className="d-flex align-items-center gap-12">
                        <span className="text-small">$0,666</span>
                        <span className="coin-btn decrease">-4,42%</span>
                      </div>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </SwiperSlide>
          <SwiperSlide key={1}>
            <div
              className="tab-pane fade show active"
              id="favorites"
              role="tabpanel"
            >
              <div className="d-flex justify-content-between">
                Name
                <p className="d-flex gap-8">
                  <span>Last price</span>
                  <span>Change</span>
                </p>
              </div>
              <ul className="mt-16">
                <li>
                  <a
                    href="choose-payment.html"
                    className="coin-item style-2 gap-12"
                  >
                    <img
                      src="images/coin/coin-6.jpg"
                      alt="img"
                      className="img"
                    />
                    <div className="content">
                      <div className="title">
                        <p className="mb-4 text-button">ETH</p>
                        <span className="text-secondary">$360,6M</span>
                      </div>
                      <div className="d-flex align-items-center gap-12">
                        <span className="text-small">$1.878,80</span>
                        <span className="coin-btn decrease">-1,62%</span>
                      </div>
                    </div>
                  </a>
                </li>
                <li className="mt-16">
                  <a
                    href="choose-payment.html"
                    className="coin-item style-2 gap-12"
                  >
                    <img
                      src="images/coin/coin-7.jpg"
                      alt="img"
                      className="img"
                    />
                    <div className="content">
                      <div className="title">
                        <p className="mb-4 text-button">arb_ETH</p>
                        <span className="text-secondary">$132,18M</span>
                      </div>
                      <div className="d-flex align-items-center gap-12">
                        <span className="text-small">$1.878,80</span>
                        <span className="coin-btn increase">+1,62%</span>
                      </div>
                    </div>
                  </a>
                </li>
                <li className="mt-16">
                  <a
                    href="choose-payment.html"
                    className="coin-item style-2 gap-12"
                  >
                    <img
                      src="images/coin/coin-8.jpg"
                      alt="img"
                      className="img"
                    />
                    <div className="content">
                      <div className="title">
                        <p className="mb-4 text-button">WBTC</p>
                        <span className="text-secondary">$50,56M</span>
                      </div>
                      <div className="d-flex align-items-center gap-12">
                        <span className="text-small">$30.001,96</span>
                        <span className="coin-btn decrease">-1,64%</span>
                      </div>
                    </div>
                  </a>
                </li>
                <li className="mt-16">
                  <a
                    href="choose-payment.html"
                    className="coin-item style-2 gap-12"
                  >
                    <img
                      src="images/coin/coin-3.jpg"
                      alt="img"
                      className="img"
                    />
                    <div className="content">
                      <div className="title">
                        <p className="mb-4 text-button">ARB</p>
                        <span className="text-secondary">$31,55M</span>
                      </div>
                      <div className="d-flex align-items-center gap-12">
                        <span className="text-small">$1,11</span>
                        <span className="coin-btn increase">+3,71%</span>
                      </div>
                    </div>
                  </a>
                </li>
                <li className="mt-16">
                  <a
                    href="choose-payment.html"
                    className="coin-item style-2 gap-12"
                  >
                    <img
                      src="images/coin/coin-9.jpg"
                      alt="img"
                      className="img"
                    />
                    <div className="content">
                      <div className="title">
                        <p className="mb-4 text-button">WETH</p>
                        <span className="text-secondary">$24,34M</span>
                      </div>
                      <div className="d-flex align-items-center gap-12">
                        <span className="text-small">$1.878,56</span>
                        <span className="coin-btn decrease">-1,62%</span>
                      </div>
                    </div>
                  </a>
                </li>
                <li className="mt-16">
                  <a
                    href="choose-payment.html"
                    className="coin-item style-2 gap-12"
                  >
                    <img
                      src="images/coin/coin-10.jpg"
                      alt="img"
                      className="img"
                    />
                    <div className="content">
                      <div className="title">
                        <p className="mb-4 text-button">MATIC</p>
                        <span className="text-secondary">$19,36M</span>
                      </div>
                      <div className="d-flex align-items-center gap-12">
                        <span className="text-small">$0,666</span>
                        <span className="coin-btn decrease">-4,42%</span>
                      </div>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </SwiperSlide>
          <SwiperSlide key={2}>
            <div
              className="tab-pane fade show active"
              id="favorites"
              role="tabpanel"
            >
              <div className="d-flex justify-content-between">
                Name
                <p className="d-flex gap-8">
                  <span>Last price</span>
                  <span>Change</span>
                </p>
              </div>
              <ul className="mt-16">
                <li>
                  <a
                    href="choose-payment.html"
                    className="coin-item style-2 gap-12"
                  >
                    <img
                      src="images/coin/coin-6.jpg"
                      alt="img"
                      className="img"
                    />
                    <div className="content">
                      <div className="title">
                        <p className="mb-4 text-button">ETH</p>
                        <span className="text-secondary">$360,6M</span>
                      </div>
                      <div className="d-flex align-items-center gap-12">
                        <span className="text-small">$1.878,80</span>
                        <span className="coin-btn decrease">-1,62%</span>
                      </div>
                    </div>
                  </a>
                </li>
                <li className="mt-16">
                  <a
                    href="choose-payment.html"
                    className="coin-item style-2 gap-12"
                  >
                    <img
                      src="images/coin/coin-7.jpg"
                      alt="img"
                      className="img"
                    />
                    <div className="content">
                      <div className="title">
                        <p className="mb-4 text-button">arb_ETH</p>
                        <span className="text-secondary">$132,18M</span>
                      </div>
                      <div className="d-flex align-items-center gap-12">
                        <span className="text-small">$1.878,80</span>
                        <span className="coin-btn increase">+1,62%</span>
                      </div>
                    </div>
                  </a>
                </li>
                <li className="mt-16">
                  <a
                    href="choose-payment.html"
                    className="coin-item style-2 gap-12"
                  >
                    <img
                      src="images/coin/coin-8.jpg"
                      alt="img"
                      className="img"
                    />
                    <div className="content">
                      <div className="title">
                        <p className="mb-4 text-button">WBTC</p>
                        <span className="text-secondary">$50,56M</span>
                      </div>
                      <div className="d-flex align-items-center gap-12">
                        <span className="text-small">$30.001,96</span>
                        <span className="coin-btn decrease">-1,64%</span>
                      </div>
                    </div>
                  </a>
                </li>
                <li className="mt-16">
                  <a
                    href="choose-payment.html"
                    className="coin-item style-2 gap-12"
                  >
                    <img
                      src="images/coin/coin-3.jpg"
                      alt="img"
                      className="img"
                    />
                    <div className="content">
                      <div className="title">
                        <p className="mb-4 text-button">ARB</p>
                        <span className="text-secondary">$31,55M</span>
                      </div>
                      <div className="d-flex align-items-center gap-12">
                        <span className="text-small">$1,11</span>
                        <span className="coin-btn increase">+3,71%</span>
                      </div>
                    </div>
                  </a>
                </li>
                <li className="mt-16">
                  <a
                    href="choose-payment.html"
                    className="coin-item style-2 gap-12"
                  >
                    <img
                      src="images/coin/coin-9.jpg"
                      alt="img"
                      className="img"
                    />
                    <div className="content">
                      <div className="title">
                        <p className="mb-4 text-button">WETH</p>
                        <span className="text-secondary">$24,34M</span>
                      </div>
                      <div className="d-flex align-items-center gap-12">
                        <span className="text-small">$1.878,56</span>
                        <span className="coin-btn decrease">-1,62%</span>
                      </div>
                    </div>
                  </a>
                </li>
                <li className="mt-16">
                  <a
                    href="choose-payment.html"
                    className="coin-item style-2 gap-12"
                  >
                    <img
                      src="images/coin/coin-10.jpg"
                      alt="img"
                      className="img"
                    />
                    <div className="content">
                      <div className="title">
                        <p className="mb-4 text-button">MATIC</p>
                        <span className="text-secondary">$19,36M</span>
                      </div>
                      <div className="d-flex align-items-center gap-12">
                        <span className="text-small">$0,666</span>
                        <span className="coin-btn decrease">-4,42%</span>
                      </div>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </SwiperSlide>
          <SwiperSlide key={3}>
            <div
              className="tab-pane fade show active"
              id="favorites"
              role="tabpanel"
            >
              <div className="d-flex justify-content-between">
                Name
                <p className="d-flex gap-8">
                  <span>Last price</span>
                  <span>Change</span>
                </p>
              </div>
              <ul className="mt-16">
                <li>
                  <a
                    href="choose-payment.html"
                    className="coin-item style-2 gap-12"
                  >
                    <img
                      src="images/coin/coin-6.jpg"
                      alt="img"
                      className="img"
                    />
                    <div className="content">
                      <div className="title">
                        <p className="mb-4 text-button">ETH</p>
                        <span className="text-secondary">$360,6M</span>
                      </div>
                      <div className="d-flex align-items-center gap-12">
                        <span className="text-small">$1.878,80</span>
                        <span className="coin-btn decrease">-1,62%</span>
                      </div>
                    </div>
                  </a>
                </li>
                <li className="mt-16">
                  <a
                    href="choose-payment.html"
                    className="coin-item style-2 gap-12"
                  >
                    <img
                      src="images/coin/coin-7.jpg"
                      alt="img"
                      className="img"
                    />
                    <div className="content">
                      <div className="title">
                        <p className="mb-4 text-button">arb_ETH</p>
                        <span className="text-secondary">$132,18M</span>
                      </div>
                      <div className="d-flex align-items-center gap-12">
                        <span className="text-small">$1.878,80</span>
                        <span className="coin-btn increase">+1,62%</span>
                      </div>
                    </div>
                  </a>
                </li>
                <li className="mt-16">
                  <a
                    href="choose-payment.html"
                    className="coin-item style-2 gap-12"
                  >
                    <img
                      src="images/coin/coin-8.jpg"
                      alt="img"
                      className="img"
                    />
                    <div className="content">
                      <div className="title">
                        <p className="mb-4 text-button">WBTC</p>
                        <span className="text-secondary">$50,56M</span>
                      </div>
                      <div className="d-flex align-items-center gap-12">
                        <span className="text-small">$30.001,96</span>
                        <span className="coin-btn decrease">-1,64%</span>
                      </div>
                    </div>
                  </a>
                </li>
                <li className="mt-16">
                  <a
                    href="choose-payment.html"
                    className="coin-item style-2 gap-12"
                  >
                    <img
                      src="images/coin/coin-3.jpg"
                      alt="img"
                      className="img"
                    />
                    <div className="content">
                      <div className="title">
                        <p className="mb-4 text-button">ARB</p>
                        <span className="text-secondary">$31,55M</span>
                      </div>
                      <div className="d-flex align-items-center gap-12">
                        <span className="text-small">$1,11</span>
                        <span className="coin-btn increase">+3,71%</span>
                      </div>
                    </div>
                  </a>
                </li>
                <li className="mt-16">
                  <a
                    href="choose-payment.html"
                    className="coin-item style-2 gap-12"
                  >
                    <img
                      src="images/coin/coin-9.jpg"
                      alt="img"
                      className="img"
                    />
                    <div className="content">
                      <div className="title">
                        <p className="mb-4 text-button">WETH</p>
                        <span className="text-secondary">$24,34M</span>
                      </div>
                      <div className="d-flex align-items-center gap-12">
                        <span className="text-small">$1.878,56</span>
                        <span className="coin-btn decrease">-1,62%</span>
                      </div>
                    </div>
                  </a>
                </li>
                <li className="mt-16">
                  <a
                    href="choose-payment.html"
                    className="coin-item style-2 gap-12"
                  >
                    <img
                      src="images/coin/coin-10.jpg"
                      alt="img"
                      className="img"
                    />
                    <div className="content">
                      <div className="title">
                        <p className="mb-4 text-button">MATIC</p>
                        <span className="text-secondary">$19,36M</span>
                      </div>
                      <div className="d-flex align-items-center gap-12">
                        <span className="text-small">$0,666</span>
                        <span className="coin-btn decrease">-4,42%</span>
                      </div>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

export default CryptoHomeList;
