import React, { useEffect, useState } from "react";
import { useLocation, NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import login_banner from "../../assets/img/login_banner.jpg";

const Signup = () => {
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const [reff_id, setReferral] = useState("");
  const [email_id, setEmail] = useState("");
  const [fullname, setFullname] = useState("");
  const [password, setPassword] = useState("");
  const [Cpassword, setCpassword] = useState("");

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const reff = queryParams.get("referral");

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post("http://localhost:3020/api/register", {
        reff,
        email_id,
        fullname,
        password,
      });
      if (response.data.token) {
        // Login successful, redirect to protected route
        sessionStorage.setItem("token", response.data.token);
        //sessionStorage.setItem('uinfo', JSON.stringify(response.data.user));
        navigate("/otp");
      } else {
        setError(response.data.message);
      }
    } catch (error) {
      setError("Error logging in");
    }
  };

  return (
    <>
      <div>
        {/*<div className="preload preload-container">
          <div className="preload-logo" style={{backgroundImage: 'url("images/logo/144.png")'}}>
            <div className="spinner" />
          </div>
        </div>
         /preload */}
        <div className="header fixed-top bg-surface">
          <NavLink to="/login" className="left back-btn">
            <i className="icon-left-btn" />
          </NavLink>
        </div>
      </div>
      <div className="pt-20 pb-20">
        <div className="tf-container">
          <div className="mt-32">
            <h2 className="text-center">NexDcx</h2>
            <h5 className="text-center">Signup To Future</h5>
            <p className="text-center">A smart exchange can change your life</p>
          </div>
          <ul className="mt-40 socials-login">
            <li className="mt-12">
              <img src={login_banner} alt="img" />
            </li>
          </ul>
          <form onSubmit={handleSubmit}>
            <fieldset className="mt-16">
              <label className="label-ip">
                <p className="mb-8 text-small"> Referral ID </p>
                <input
                  type="text"
                  defaultValue={reff}
                  value={reff_id}
                  onChange={(event) => setReferral(event.target.value)}
                  placeholder="Referral Address"
                  required
                />
              </label>
            </fieldset>
            <fieldset className="mt-16">
              <label className="label-ip">
                <p className="mb-8 text-small"> Email Address </p>
                <input
                  type="email"
                  value={email_id}
                  onChange={(event) => setEmail(event.target.value)}
                  placeholder="Enter Email Address"
                  required
                />
              </label>
            </fieldset>
            <fieldset className="mt-16">
              <label className="label-ip">
                <p className="mb-8 text-small"> Fullname </p>
                <input
                  type="text"
                  value={fullname}
                  onChange={(event) => setFullname(event.target.value)}
                  placeholder="Enter Fullname"
                  required
                />
              </label>
            </fieldset>
            <fieldset className="mt-16">
              <label className="label-ip">
                <p className="mb-8 text-small"> Password </p>
                <input
                  type="password"
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                  placeholder="Enter Password"
                  required
                />
              </label>
            </fieldset>
            <fieldset className="mt-16">
              <label className="label-ip">
                <p className="mb-8 text-small"> Confirm Password </p>
                <input
                  type="text"
                  value={Cpassword}
                  onChange={(event) => setCpassword(event.target.value)}
                  placeholder="Confirm Password"
                  required
                />
              </label>
            </fieldset>

            <button className="mt-20" type="submit">
              Signup
            </button>
          </form>
          <p className="mt-20 text-center text-small">
            Already have a Account?  <NavLink to="/login">Sign In</NavLink>
          </p>
        </div>
      </div>
    </>
  );
};

export default Signup;
