import React from 'react'
import { Header } from '../header/Header';
import './Home.css';
import MySwiperComponent from '../util/MySwiperComponent';
import CryptoHomeList from '../util/CryptoHomeList';
import Footer from '../util/Footer';
import { isLogedIn } from '../auth/UserAuth';
import { NavLink } from 'react-router-dom';

const Home = () => {
  isLogedIn();
  return (
    <>
    <Header />
  <div className="pt-68 pb-80">
    <div className="bg-menuDark tf-container">
      <div className="pt-12 pb-12 mt-4">
        <h5>
          <span className="text-primary">My Wallet</span> -{" "}
          <a
            href="#"
            className="choose-account"
            data-bs-toggle="modal"
            data-bs-target="#accountWallet"
          >
            <span className="dom-text">Account 1 </span> &nbsp;
            <i className="icon-select-down" />
          </a>{" "}
        </h5>
        <h1 className="mt-16">
          <a>$0.00</a>
        </h1>
        <ul className="mt-16 grid-4 m--16">
          <li>
            <NavLink
              to="choose-payment"
              className="tf-list-item d-flex flex-column gap-8 align-items-center"
            >
              <span className="box-round bg-surface d-flex justify-content-center align-items-center">
                <i className="icon icon-way" />
              </span>
              Send
            </NavLink>
          </li>
          <li>
            <NavLink
              to="qr-code2"
              className="tf-list-item d-flex flex-column gap-8 align-items-center"
            >
              <span className="box-round bg-surface d-flex justify-content-center align-items-center">
                <i className="icon icon-way2" />
              </span>
              Receive
            </NavLink>
          </li>
          <li>
            <NavLink
              to="buy-quantity"
              className="tf-list-item d-flex flex-column gap-8 align-items-center"
            >
              <span className="box-round bg-surface d-flex justify-content-center align-items-center">
                <i className="icon icon-wallet" />
              </span>
              Buy
            </NavLink>
          </li>
          <li>
            <NavLink
              to="earn"
              className="tf-list-item d-flex flex-column gap-8 align-items-center"
            >
              <span className="box-round bg-surface d-flex justify-content-center align-items-center">
                <i className="icon icon-exchange" />
              </span>
              Stake
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
    <div className="bg-menuDark tf-container">
      <div className="pt-12 pb-12 mt-4">
        <h5>My Assets</h5>
        <MySwiperComponent />
      </div>
    </div>
    <div className="bg-menuDark tf-container">
      <CryptoHomeList />
    </div>
  </div>
  <Footer />
  {/* account */}
  <div className="modal fade action-sheet" id="accountWallet">
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <div className="modal-header">
          <span>Wallet</span>
          <span className="icon-cancel" data-bs-dismiss="modal" />
        </div>
        <ul className="mt-20 pb-16">
          <li data-bs-dismiss="modal">
            <div className="d-flex justify-content-between align-items-center gap-8 text-large item-check active dom-value">
              Account 1 <i className="icon icon-check-circle" />{" "}
            </div>
          </li>
          <li className="mt-4" data-bs-dismiss="modal">
            <div className="d-flex  justify-content-between gap-8 text-large item-check dom-value">
              Account 2<i className="icon icon-check-circle" />
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  
  
  {/* noti popup 2*/}
  <div className="modal fade modalCenter" id="notiPrivacy">
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content p-20">
        <div className="heading">
          <h3>Privacy</h3>
          <div className="mt-4 text-small">
            <p>
              A mobile app privacy policy is a legal statement that must be
              clear, conspicuous, and consented to by all users. It must
              disclose how a mobile app gathers, stores, and uses the personally
              identifiable information it collects from its users.
            </p>
            <p>
              A mobile privacy app is developed and presented to users so that
              mobile app developers stay compliant with state, federal, and
              international laws. As a result, they fulfill the legal
              requirement to safeguard user privacy while protecting the company
              itself from legal challenges.
            </p>
          </div>
          <h3 className="mt-12">Authorized Users</h3>
          <p className="mt-4 text-small">
            A mobile app privacy policy is a legal statement that must be clear,
            conspicuous, and consented to by all users. It must disclose how a
            mobile app gathers, stores, and uses the personally identifiable
            information it collects from its users.
          </p>
          <div className="cb-noti mt-12">
            <input type="checkbox" className="tf-checkbox" id="cb-ip" />
            <label htmlFor="cb-ip">
              I agree to the Term of service and Privacy policy
            </label>
          </div>
        </div>
        <div className="mt-20">
          <a href="#" className="tf-btn md primary" data-bs-dismiss="modal">
            I Accept
          </a>
        </div>
      </div>
    </div>
  </div>
</>

  );
}

export default Home