import React, { useState, useEffect } from "react";
import avt2 from "../../assets/images/avt/avt2.jpg";
import { NavLink } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { API_URL, token } from '../util/Constants';
import TimeAgo from "../helper/TimeAgo";

export const Header = () => {
  const [show, setShow] = useState(false);
  const showNotification = () => setShow(true);
  const handleClose = () => setShow(false);
  
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch(API_URL+`/api/notification/${token}`)
      .then(response => response.json())
      .then(data => setData(data.NotifyData))
      .catch(error => console.error(error));
      
  }, []);

  return (
    <>
      <div className="header-style2 fixed-top bg-menuDark">
        <div className="d-flex justify-content-between align-items-center gap-14">
          <div className="box-account style-2">
            <NavLink to="/user-info">
              <img src={avt2} alt="img" className="avt" />
            </NavLink>
            <div className="search-box box-input-field style-2">
              <NavLink to="home-search" className="icon-search" />
              <input
                type="text"
                placeholder="Looking for stock"
                required=""
                className="clear-ip"
              />
              <i className="icon-close" />
            </div>
          </div>
          <div className="d-flex align-items-center gap-8">
            <NavLink to="list-blog" className="icon-gift" />
            <a onClick={showNotification} className="icon-noti box-noti" />
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        size="sm"
        className="modalRight"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {/* notification */}

        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="header fixed-top bg-surface d-flex justify-content-center align-items-center">
              <span className="left" data-bs-dismiss="modal" aria-hidden="true" onClick={handleClose}>
                <i className="icon-left-btn" />
              </span>
              <h3>Notification</h3>
            </div>
            <div className="overflow-auto pt-45 pb-16">
              <div className="tf-container">
                <ul className="mt-12">
                {data.map(item => (
                  <li className="mt-12" key={item.id}>
                    <a href="#" className="noti-item bg-menuDark">
                      <div className="pb-8 line-bt d-flex">
                        <p className="text-button fw-6">
                        {item.massage} 
                        </p>
                        <i className="dot-lg bg-primary" />
                      </div>
                      <span className="d-block mt-8"> <TimeAgo date={item.onDate} /></span>
                    </a>
                  </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/* noti popup */}
      </Modal>
      <div className="modal fade modalCenter" id="modalNoti">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content modal-sm">
            <div className="p-16 line-bt text-center">
              <h4>“Cointex” Would Like To Send You Notifications</h4>
              <p className="mt-8 text-large">
                Notifications may include alerts, sounds, and icon badges. These
                can be configured in Settings.
              </p>
            </div>
            <div className="grid-2">
              <a
                href="#"
                className="line-r text-center text-button fw-6 p-10 text-secondary btn-hide-modal"
                data-bs-dismiss="modal"
              >
                Don’t Allow
              </a>
              <a
                href="#"
                className="text-center text-button fw-6 p-10 text-primary btn-hide-modal"
                data-bs-toggle="modal"
                data-bs-target="#notiPrivacy"
              >
                {" "}
                Allow
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
