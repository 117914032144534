import React, { useEffect, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import market1 from '../../assets/images/coin/market-1.jpg';
import market3 from '../../assets/images/coin/market-3.jpg';
import coin3 from '../../assets/images/coin/coin-3.jpg';
import { API_URL, token } from '../util/Constants';

// Import Swiper styles
import "swiper/css";
import { NavLink } from "react-router-dom";

const MySwiperComponent = () => {
  const [data, setData] = useState([]);
  
  const getRandomValue = () => {
    const walletBgList = ['bg1', 'bg2', 'bg3'];
    const randomIndex = Math.floor(Math.random() * walletBgList.length);
    const WalletBg = "blur " + walletBgList[randomIndex];
    return WalletBg;
  };

  useEffect(() => {
    fetch(API_URL+`/api/wallets/${token}`)
      .then(response => response.json())
      .then(data => setData(data.Wallets))
      .catch(error => console.error(error));
      
  }, []);
  
  return (
    <>
    <Swiper className="mySwiper" autoplay={{ delay: 3000 }} spaceBetween={10} slidesPerView={2}>
    {data.map(item => (
        <SwiperSlide>
            <div className="swiper-slide">
              <NavLink to="exchange-market" className="coin-box d-block">
                <div className="coin-logo">
                  <img
                    src={market1}
                    alt="img"
                    className="logo"
                  />
                  <div className="title">
                    <p>{ item.wallet_name }</p>
                    <span>BTC</span>
                  </div>
                </div>
                <div className="mt-8 mb-8 coin-chart">
                  <div id="line-chart-1" />
                </div>
                <div className="coin-price d-flex justify-content-between">
                  <span>${ item.balance }</span>
                  <span className="text-primary d-flex align-items-center gap-2">
                    <i className="icon-select-up" /> 11,75%
                  </span>
                </div>
                <div className={getRandomValue()}></div>
              </NavLink>
            </div>
        </SwiperSlide>
        ))}
        <SwiperSlide>
            <div className="swiper-slide">
              <NavLink to="exchange-market" className="coin-box d-block">
                <div className="coin-logo">
                  <img
                    src={market3}
                    alt="img"
                    className="logo"
                  />
                  <div className="title">
                    <p>Binance</p>
                    <span>BNB</span>
                  </div>
                </div>
                <div className="mt-8 mb-8 coin-chart">
                  <div id="line-chart-2" />
                </div>
                <div className="coin-price d-flex justify-content-between">
                  <span>$0.00</span>
                  <span className="text-primary d-flex align-items-center gap-2">
                    <i className="icon-select-up" /> 21,59%
                  </span>
                </div>
                <div className="blur bg2"></div>
              </NavLink>
            </div>
        </SwiperSlide>
        <SwiperSlide>
            <div className="swiper-slide">
              <NavLink to="exchange-market" className="coin-box d-block">
                <div className="coin-logo">
                  <img
                    src={coin3}
                    alt="img"
                    className="logo"
                  />
                  <div className="title">
                    <p>Ethereum</p>
                    <span>ETH</span>
                  </div>
                </div>
                <div className="mt-8 mb-8 coin-chart">
                  <div id="line-chart-3" />
                </div>
                <div className="coin-price d-flex justify-content-between">
                  <span>$0.00</span>
                  <span className="text-primary d-flex align-items-center gap-2">
                    <i className="icon-select-up" /> 4,75%
                  </span>
                </div>
                <div className="blur bg3"></div>
              </NavLink>
            </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
};

export default MySwiperComponent;
