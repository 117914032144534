import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import axios from 'axios';
import login_banner from "../../assets/img/login_banner.jpg";
import { API_URL } from '../util/Constants';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(API_URL+'/api/login', { username, password });
      if (response.data.token) {
        // Login successful, redirect to protected route
        sessionStorage.setItem('token', response.data.token);
        sessionStorage.setItem('user', JSON.stringify(response.data.user));
        navigate('/home');
      } else {
        setError(response.data.message);
      }
    } catch (error) {
      setError('Error logging in');
    }
  };

  return (
    <>
      <div>
        {/*<div className="preload preload-container">
          <div className="preload-logo" style={{backgroundImage: 'url("images/logo/144.png")'}}>
            <div className="spinner" />
          </div>
        </div>
         /preload */}
        <div className="header fixed-top bg-surface">
          <NavLink to="/Signup" className="left back-btn">
            <i className="icon-left-btn" />
          </NavLink>
        </div>
      </div>
      <div className="pt-45 pb-20">
        <div className="tf-container">
          <div className="mt-32">
            <h2 className="text-center">NexDcx</h2>
            <h5 className="text-center">Signup To Future</h5>
            <p className="text-center">A smart exchange can change your life</p>
          </div>
          <ul className="mt-40 socials-login">
            <li className="mt-12">
              <img src={login_banner} alt="img" />
            </li>
          </ul>
          <form onSubmit={handleSubmit}>
          <fieldset className="mt-16">
            <label className="label-ip">
              <p className="mb-8 text-small"> Email</p>
              <input
                type="email"
                value={username} onChange={(event) => setUsername(event.target.value)}
                placeholder="Enter email address"
              />
            </label>
          </fieldset>

          <fieldset className="mt-16">
            <label className="label-ip">
              <p className="mb-8 text-small"> Password</p>
              <input type="password" value={password} onChange={(event) => setPassword(event.target.value)} placeholder="Enter Password" />
            </label>
          </fieldset>

          <button className="mt-20" type="submit">
            Login
          </button>
          </form>
          <p className="mt-20 text-center text-small">
            Dont have a Account?  <NavLink to="/signup">Sign up</NavLink>
          </p>
        </div>
      </div>
    </>
  );
};

export default Login;
