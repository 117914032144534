import React from 'react'
import { NavLink } from 'react-router-dom';

const Footer = () => {
  return (
    <div className="menubar-footer footer-fixed">
    <ul className="inner-bar">
      <li className="active">
        <NavLink to="/">
          <i className="icon icon-home2" />
          Home
        </NavLink>
      </li>
      <li>
        <NavLink to="exchange-market">
          <i className="icon icon-exchange" />
          Exchange
        </NavLink>
      </li>
      <li>
        <NavLink to="earn">
          <i className="icon icon-earn" />
          Earn
        </NavLink>
      </li>
      <li>
        <NavLink to="wallet">
          <i className="icon icon-wallet" />
          Wallet
        </NavLink>
      </li>
    </ul>
  </div>
  )
}

export default Footer;