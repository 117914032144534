import React, { useState } from 'react';

export const Otp = () => {
  const [otp1, setOtp1] = useState('');
  const [otp2, setOtp2] = useState(null);
  const [otp3, setOtp3] = useState(null);
  const [otp4, setOtp4] = useState(null);
  const [error, setError] = useState(null);
  const [massage, setSuccess] = useState(false);

  const handleVerify = async () => {
    try {
      const otp = otp1+''+otp2+''+otp3+''+otp4;
      const response = await fetch('http://localhost:3001/api/verify-otp', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ otp }),
      });
      const data = await response.json();
      if (data.success) {
        setError('');
        setSuccess("OTP Verifyed.");
      } else {
        setError(data.error);
      }
    } catch (error) {
      setError('An error occurred while verifying OTP');
    }
  };

  return (
    <>
  <div className="header fixed-top bg-surface d-flex justify-content-center align-items-center">
    <a href="#" className="left back-btn">
      <i className="icon-left-btn" />
    </a>
    <h3>OTP</h3>
  </div>
  <div className="pt-45 pb-20">
    <div className="tf-container">
    {error && <div className='alert alert-danger' style={{ color: 'red' }}>{error}</div>}
    {massage && <div className='alert alert-success'>{massage}</div>}
        <div className="digit-group mt-12">
          <input
            required=""
            type="text"
            id="digit-2"
            name="digit-2"
            data-next="digit-3"
            data-previous="digit-1"
            value={otp1} onChange={(event) => setOtp1(event.target.value)}
          />
          <input
            required=""
            type="text"
            id="digit-3"
            name="digit-3"
            data-next="digit-4"
            data-previous="digit-2"
            value={otp2} onChange={(event) => setOtp2(event.target.value)}
          />
          <input
            required=""
            type="text"
            id="digit-4"
            name="digit-4"
            data-next="digit-5"
            data-previous="digit-3"
            value={otp3} onChange={(event) => setOtp3(event.target.value)}
          />
          <input
            required=""
            type="text"
            id="digit-5"
            name="digit-5"
            data-next="digit-6"
            data-previous="digit-4"
            value={otp4} onChange={(event) => setOtp4(event.target.value)}
          />
        </div>
        <p className="text-center text-small text-white mt-16">
          A code has been sent to your phone
        </p>
        <p className="d-flex justify-content-center mt-4 text-center text-button text-primary fw-6">
          Resend in&nbsp;
          <span
            className="js-countdown"
            data-timer={60}
            data-labels=" :  ,  : , : , "
          />
        </p>
        <button className="mt-40 tf-btn lg primary" onClick={handleVerify}>
          Verify
        </button>
      
    </div>
  </div>
</>

  )
}
