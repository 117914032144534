const AuthToken = sessionStorage.getItem("token");

export function isLogedIn() {
    setInterval(() => {
        const token = sessionStorage.getItem('token');
        if (!token) {
        window.location.href = '/login';
    }
    }, 2000);
  }

export function Logout() {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('uinfo');
    window.location.href = '/login';
  };
